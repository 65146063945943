import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Outlet } from "react-router-dom";

const Resume = () => {
  return (
    <div className="container resume-page">
      {/* start of job experience */}
      <Container>
        <h1 className="resume-headers">Experience</h1>
        <Row>
          <Col xs="auto">
            <Card style={{}}>
              <Card.Body>
                <Card.Title className="resume-card-titles">DentiMax</Card.Title>
                <Card.Subtitle className="mb-2 resume-card-titles">
                  Tier 2 Software Support
                </Card.Subtitle>
                <div>
                  <p>Feb 2018-July 2021</p>
                  <p>Troubleshot DentiMax Dental Software for customers.</p>
                  <p>
                    Used SQL to navigate, fix, update, delete and create tables
                    in DentiMax.
                  </p>
                  <p>Tested software before production release. </p>
                  <p>
                    Maintained, managed and updated company support website with
                    new information for troubleshooting.
                  </p>
                </div>
                <Card.Link href="https://dentimax.com/" target="_blank" className="btn btn-primary">
                  DentiMax Homepage
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="auto">
            <Card style={{}}>
              <Card.Body>
                <Card.Title className="resume-card-titles">Vault Data Servers LLC</Card.Title>
                <Card.Subtitle className="mb-2 resume-card-titles">
                  Network Engineer
                </Card.Subtitle>
                <div>
                  <p>July 2017-September 2017</p>
                  <p>
                    Contract position for 3 months where I set up a Dental
                    Office network.
                  </p>
                  <p>
                    Ran cabpng in building to set up a cpent/server connection
                  </p>
                </div>
                <Card.Link href="https://vau.dev/" target="_blank" className="btn btn-primary">
                  Vault Data Servers
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* end of job experience */}

      {/* start of education */}

      <Container>
      <h1 className="resume-headers">Education</h1>
        <Row>
          <Col xs="auto">
            <Card style={{}}>
              <Card.Body>
                <Card.Title className="resume-card-titles">Brigham Young University-Idaho</Card.Title>
                <Card.Subtitle className="mb-2 resume-card-titles">
                  Bachelors in Applied Technology
                </Card.Subtitle>
                <div>
                  <p>GPA: 3.7</p>
                  <p>Start: January 2019</p>
                  <p>End: April 2023</p>
                </div>
                <Card.Link href="https://www.byui.edu/" target="_blank" className="btn btn-primary">
                  BYU-I
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col xs="auto">
            <Card style={{ }}>
              <Card.Body>
                <Card.Title className="resume-card-titles">Mountain View High School</Card.Title>
                <Card.Subtitle className="mb-2 resume-card-titles">
                  High School Diploma
                </Card.Subtitle>
                <div>
                  <p>Graduated: May 2017</p>
                </div>
                <Card.Link href="https://www.mpsaz.org/mtnview" className="btn btn-primary">
                  Moutain View
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* end of education */}

      {/* start of skills */}
      <Container className="skills-section">
      <h1 className="resume-headers" style={{color:'#01161E'}}>Skills</h1>
        <Row>
          <Col xs="4">
            <p>HTML</p>
            <p>CSS</p>
            <p>JavaScript</p>
          </Col>
          <Col xs="4">
            <p>C#</p>
            <p>Angular</p>
            <p>React</p>
          </Col>
          <Col xs="4">
            <p>TypeScript</p>
            <p>SQL</p>
            <p>MongoDb</p>
          </Col>
        </Row>
      </Container>
      {/* end of skills */}

      <Container className="exp-section">
        <h1 className="resume-headers">Experience</h1>
        <Row>
          <Col>
            <Card style={{}}>
              <Card.Body>
                <Card.Title className="resume-card-titles">Computer Programming Cert</Card.Title>
                <Card.Subtitle className="mb-2 resume-card-titles">
                  Brigham Young University-Idaho
                </Card.Subtitle>
                <div>
                  Certification from BYU-I that shows I am a certified
                  programmer.
                </div>
                <Card.Link
                  href="https://www.byui.edu/student-records-and-registration?dvid=462TZHUM"
                  target="_blank" className="btn btn-primary"
                >
                  Cert link
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{}}>
              <Card.Body>
                <Card.Title className="resume-card-titles">Frontend Web Development Cert</Card.Title>
                <Card.Subtitle className="mb-2 resume-card-titles">
                  Brigham Young University-Idaho
                </Card.Subtitle>
                <div>
                  Certification from BYU-I that shows I am a certified Web
                  Frontend developer.
                </div>
                <Card.Link
                  href="https://www.byui.edu/student-records-and-registration?dvid=2YWHRFKG"
                  target="_blank" className="btn btn-primary"
                >
                  Cert link
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{}}>
              <Card.Body>
                <Card.Title className="resume-card-titles">Backend Web Development Cert</Card.Title>
                <Card.Subtitle className="mb-2 resume-card-titles">
                  Brigham Young University-Idaho
                </Card.Subtitle>
                <div>
                  Certification from BYU-I that shows I am a certified Backend Web
                  developer.
                </div>
                <Card.Link
                  href="https://www.byui.edu/student-records-and-registration?dvid=KYWPTFQ7"
                  target="_blank" className="btn btn-primary"
                >
                  Cert link
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
          <Col>
            <Card style={{}}>
              <Card.Body>
                <Card.Title className="resume-card-titles">NodeJS Certification</Card.Title>
                <Card.Subtitle className="mb-2 resume-card-titles">Udemy</Card.Subtitle>
                <div>
                  Certification that shows I know NodeJs, MVC, Rest APIs,
                  GraphQL and Deno.
                </div>
                <Card.Link
                  href="https://www.udemy.com/certificate/UC-24dd96b6-244b-46da-aad8-b43499b51767/?utm_source=sendgrid.com&utm_medium=email&utm_campaign=email"
                  target="_blank" className="btn btn-primary"
                >
                  Cert link
                </Card.Link>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Outlet></Outlet>
    </div>
  );
};

export default Resume;
