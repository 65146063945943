import React from "react";
import './index.css'
import './App.css'
import "bootstrap/dist/css/bootstrap.min.css";
import GetRoutes from "./components/getRoutes";
import Navbar from "./components/Navbar"
import FooterSection from "./components/FooterSection";

function App() {
  return (
    <div className="bg-color-lg">
      <Navbar />
      <GetRoutes />
      <link
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css"
        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3"
        crossOrigin="anonymous"
      />
      <FooterSection />
    </div>
  );
}

export default App;
