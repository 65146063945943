import React, { Component } from "react";
import { Route, Routes } from "react-router-dom";

import Home from "../pages/Home";
import ProjectPage from "../pages/ProjectPage";
import Resume from "../pages/Resume";

class GetRoutes extends Component {

  render() {
    return (
      <Routes>
        <Route exact path="/" element={<Home />}></Route>
        <Route path="/resume" element={<Resume />}/>
        <Route path="/projects" element={<ProjectPage />}/>
        <Route path="*" element={<Home />}></Route>
      </Routes>
    );
  }
}

export default GetRoutes;
