import React from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import {saveAs} from 'file-saver';

const Header = () => {
    //creates the ability to download resume using a button
    const saveFile = () => {
        saveAs("./files/Harrison_Bowlby_Resume.pdf", "Harrison_Bowlby.pdf")
    }

  return (
    <Container fluid>
      <Row>
        {/* <Col xs={5}></Col> */}
        {/* <Col xs={2}> */}
          <Card style={{ width: "18rem", backgroundColor: '#598392', margin: '10px auto' }}>
            <Card.Img 
            variant="top" 
            src="./images/me.jpg"
            style={{
              padding: "5px",
              margin: "3px",
              borderRadius: "20%"
            }}/>
            <Card.Body>
              <Card.Title style={{ textAlign: 'center' }}>This is me!</Card.Title>
              <hr />
              <Card.Text className="card-p">
                Full time college student at BYUI currently seeking an
                internship in web development.
              </Card.Text>
              <Button variant="primary" onClick={saveFile}>Download Resume</Button>
            </Card.Body>
          </Card>
        {/* </Col> */}
        {/* <Col xs={5}></Col> */}
      </Row>
    </Container>
  );
};

export default Header;
