import React from "react";

function FooterSection() {
  const getYear = () => {
    return new Date().getFullYear();
  };
  return (
    <div
      style={{
        backgroundColor: "#AEC3B0",
        width: "70%",
        margin: "0 auto",
        textAlign: "center",
        height: "50px",
      }}
    >
      Harrison Bowlby | ©<span>{getYear()}</span>
    </div>
  );
}

export default FooterSection;
