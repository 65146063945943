import React from 'react';
import Header from '../components/Header';

const Home = () => {
  return (
    <div className='container'>
        <Header></Header>

        <div className="home-div">
        <p className='home-p'>This was my attempt at building a React Website with little to no knowledge of React. I'm very proud of it and have worked on it from time to time. Currently, I am constructing a free resume building application to add to my portfolio. It will not be built with React, but rather with Django.</p>
        <p className='home-p'> A lot has happened recently and I haven't had much time to practice web development. However, my college career is coming to a close and I've made my main focus on trying to get an internship. Feel free to check out this webpage, but know in the future it will be revamped entirely once I've expanded my knowledge of JavaScript and Django.</p>
        </div>
    </div>
  )
}

export default Home