import React from "react";
import { Container, Row, Col, Card, Button } from "react-bootstrap";

const ProjectPage = () => {
  return (
    <Container className="project-page">
      <h1
        className="project-header"
        style={{
          padding: "5px",
          borderBottom: "5px solid #598392",
        }}
      >
        Current Projects
      </h1>
      <Row>
        <Col>
          <Card style={{  }}>
            <Card.Img variant="top" src="./images/pyresume.PNG" />
            <Card.Body>
              <Card.Title>Pyresume a Django Based Resume Design App</Card.Title>
              <Card.Text>
                This is my first Django web app that converts user input data
                into a Professional PDF.
              </Card.Text>
              <Button variant="primary" className="button-link">
                <a
                  href="https://www.pyresume.com"
                  target="_blank"
                  rel="noreferrer"
                  className="linkText-white"
                >
                  Check it out
                </a>
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card style={{  }}>
            <Card.Img variant="top" src="./images/firstSite.PNG" />
            <Card.Body>
              <Card.Title>First Website</Card.Title>
              <Card.Text>
                First real website using HTML, CSS and some vanilla JavaScript.
                It is not optimized.
              </Card.Text>
              <Button variant="primary" className="button-link">
                <a
                  href="https://hbowlby1.github.io/wdd130/exotic_plants/index.html"
                  target="_blank"
                  rel="noreferrer"
                  className="linkText-white"
                >
                  Check it out
                </a>
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card style={{  }}>
            <Card.Img variant="top" src="./images/lazyLoading.PNG" style={{ margin: '0 auto'}}/>
            <Card.Body>
              <Card.Title>Lazy Loading</Card.Title>
              <Card.Text>
                I am really proud of this project. It was an assignment for
                college and I just had to include. Plus, Star Wars.
              </Card.Text>
              <Button variant="primary" className="button-link">
                <a
                  href="https://hbowlby1.github.io/WDD230/lesson-7/lazyload.html"
                  target="_blank"
                  rel="noreferrer"
                  className="linkText-white"
                >
                  Check it out
                </a>
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card style={{  }}>
            <Card.Img variant="top" src="./images/secondRealSite.PNG" />
            <Card.Body>
              <Card.Title>Second Website</Card.Title>
              <Card.Text>
                This is another college assignment. It is made with HTML, CSS,
                Vanilla JS and the Open Weather API.
              </Card.Text>
              <Button variant="primary" className="button-link">
                <a
                  href="https://hbowlby1.github.io/WDD230/cactus_commerce/index.html"
                  target="_blank"
                  rel="noreferrer"
                  className="linkText-white"
                >
                  Check it out
                </a>
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Card style={{  }}>
            <Card.Img variant="top" src="./images/zenGarden.PNG" />
            <Card.Body>
              <Card.Title>My Zen Garden</Card.Title>
              <Card.Text>
                Again another college assignment that had me create my own Zen
                Garden variant. This was done with SASS and SVG.
              </Card.Text>
              <Button variant="primary" className="button-link">
                <a
                  href="https://hbowlby1.github.io/WDD331/zengarden/index.html"
                  target="_blank"
                  rel="noreferrer"
                  className="linkText-white"
                >
                  Check it out
                </a>
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card style={{  }}>
            <Card.Img variant="top" src="./images/firstPortfolio.PNG" />
            <Card.Body>
              <Card.Title>Entire College Portfolio</Card.Title>
              <Card.Text>
                If you'd like to see my entire college portfolio of assignments.
                Feel free to check it out.
              </Card.Text>
              <Button variant="primary" className="button-link">
                <a
                  href="https://hbowlby1.github.io"
                  target="_blank"
                  rel="noreferrer"
                  className="linkText-white"
                >
                  Check it out
                </a>
              </Button>
            </Card.Body>
          </Card>
        </Col>

        <Col>
          <Card style={{  }}>
            <Card.Img variant="top" src="./images/GitHub_Logo.png" />
            <Card.Body>
              <Card.Title>My Github</Card.Title>
              <Card.Text>
                Or if you like to look at Github, you can check it here. I'm
                always adding new stuff especially with college coming to an end
                soon.
              </Card.Text>
              <Button variant="primary" className="button-link">
                <a
                  href="https://github.com/hbowlby1"
                  target="_blank"
                  rel="noreferrer"
                  className="linkText-white"
                >
                  Check it out
                </a>
              </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card style={{  }}>
            <Card.Img variant="top" src="./images/recruiter_buster.PNG" />
            <Card.Body>
              <Card.Title>Recruiter Buster</Card.Title>
              <Card.Text>
                A website that allows future and current employees to give job recruiters reviews.
              </Card.Text>
              <Button variant="primary" className="button-link">
                <a
                  href="https://www.recruiterbuster.com"
                  target="_blank"
                  rel="noreferrer"
                  className="linkText-white"
                >
                  Check it out
                </a>
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      
    </Container>
  );
};

export default ProjectPage;
